import * as React from "react"

const ManualIcon = ({ color = "currentColor", ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
      <path d="m9.594 2.475 2.828 2.829-.884.883L8.71 3.36z" fill={color} />
      <path
        clip-rule="evenodd"
        d="m12.952 2.652-.707-.707a.25.25 0 0 0-.353 0L6.839 6.997a.25.25 0 0 0-.06.098L6.266 8.63l1.535-.511a.25.25 0 0 0 .097-.06l5.053-5.054a.25.25 0 0 0 0-.353Zm.884-.884a1.5 1.5 0 0 1 0 2.121L8.783 8.942a1.5 1.5 0 0 1-.586.362l-2.01.67A1 1 0 0 1 4.924 8.71l.67-2.01a1.5 1.5 0 0 1 .362-.585l5.053-5.053a1.5 1.5 0 0 1 2.121 0l.707.707Z"
        fill={color}
        fill-rule="evenodd"
      />
      <path
        clip-rule="evenodd"
        d="M0 4.5a2.25 2.25 0 0 1 2.25-2.25h3.5a.75.75 0 0 1 0 1.5h-3.5a.75.75 0 0 0-.75.75v8c0 .414.336.75.75.75h7a.75.75 0 0 0 .75-.75V10a.75.75 0 0 1 1.5 0v2.5a2.25 2.25 0 0 1-2.25 2.25h-7A2.25 2.25 0 0 1 0 12.5v-8Z"
        fill-rule="evenodd"
        fill={color}
      />
    </svg>
  )
}

export default ManualIcon
