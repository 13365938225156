import * as React from "react"

const SportsLine = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clip-path="url(#a)" fill="#10D669">
        <path d="m5 14.865 4.736-1.006V7.32l7.549-1.605s.951-.156 1.34-.79c.452-.734.452-1.87.452-1.87V0L5.027 2.987v.006L5 2.998v11.867ZM19.066 9.14h.008l-2.355.503V9.64l-2.36.501v.004h-.004.003v6.523l-1.75.372-4.151.882v.004l-1.687.358s-.64.115-1.208.825c-.577.72-.562 1.913-.562 1.913v2.98l14.051-2.986v-.006l.027-.006V9.137l-.012.002Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" transform="translate(5)" d="M0 0h14.097v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SportsLine
